<template>
    <div>
        <b-tabs class="ideas-tabs">
            <b-tab :title="$t('Timeline')" active>
                <timeline></timeline>
            </b-tab>
            <b-tab
                :title="showDescripiton ? $t('Description') : $t('Attachments')"
            >
                <description :show-descripiton="showDescripiton"></description>
            </b-tab>
            <b-tab :title="$t('Ideators')">
                <ideators></ideators>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import Description from '@/modules/ideas/components/details/Description'
import Ideators from '@/modules/ideas/components/details/Ideators'
import Timeline from '@/modules/ideas/components/details/Timeline'
export default {
    components: {
        Description,
        Timeline,
        Ideators,
    },
    props: {
        showDescripiton: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss" scoped>
/deep/ {
    .ideas-tabs {
        .tab-content {
            padding-top: rem(48px);
        }
    }
}
</style>
