import _ from 'lodash'

import { IdeasHelper } from '@/common/crud-helpers/ideas'
export const ideasReviews = {
    methods: {
        social(data) {
            if (data.type == 'like') {
                IdeasHelper.postLike(this.uuid(data.object.uuid)).then(resp => {
                    if (resp.data.id) {
                        data.object.i_like = true
                        data.object.likes_count++
                        if (this.$refs.refReviews) {
                            this.$refs.refReviews.likes.push(resp.data)
                        }
                    } else {
                        data.object.i_like = false
                        data.object.likes_count--
                        if (this.$refs.refReviews) {
                            let likes = _.remove(
                                this.$refs.refReviews.likes,
                                function(like) {
                                    like.user === resp.data.user
                                },
                            )
                            this.$refs.refReviews.likes = likes
                        }
                    }
                })
            } else if (data.type == 'follower') {
                IdeasHelper.postFollower(this.uuid(data.object.uuid)).then(
                    resp => {
                        if (resp.data.id) {
                            data.object.i_follow = true
                            data.object.followers_count++
                            if (this.$refs.refReviews) {
                                this.$refs.refReviews.followers.push(resp.data)
                            }
                        } else {
                            data.object.i_follow = false
                            data.object.followers_count--
                            if (this.$refs.refReviews) {
                                let followers = _.remove(
                                    this.$refs.refReviews.followers,
                                    function(like) {
                                        like.user === resp.data.user
                                    },
                                )
                                this.$refs.refReviews.followers = followers
                            }
                        }
                    },
                )
            } else if (data.type == 'contributor') {
                // IdeasHelper.postFollower(this.uuid(data.object.uuid)).then(resp => {
                //     if (resp.data.id) {
                //         data.object.i_contribute = true;
                //         data.object.contributor_count++;
                //     } else {
                //         data.object.i_contribute = false;
                //         data.object.contributor_count--;
                //     }
                // });
            }
        },
    },
}
