<template>
    <div>
        <div v-if="showDescripiton" class="description">
            <h2>{{ ideaObject.title }}</h2>
            <p>
                {{ ideaObject.description }}
            </p>
        </div>
        <!-- ideaList -->
        <documents-list :show-descripiton="showDescripiton"></documents-list>
        <!-- ideaList -->
    </div>
</template>

<script>
import DocumentsList from '@/modules/ideas/components/details/DocumentsList.vue'
import { mapGetters } from 'vuex'

export default {
    components: {
        DocumentsList,
    },
    props: {
        showDescripiton: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters(['ideaObject']),
    },
}
</script>
<style lang="scss" scoped>
.description {
    h2 {
        color: var(--primary);
        font-size: rem(24px);
        font-weight: 900;
        margin-bottom: rem(18px);
        text-transform: uppercase;
    }
    p {
        color: #333333;
        line-height: 1.6;
        text-align: justify;
    }
}
</style>
