import { IdeasHelper } from '@/common/crud-helpers/ideas'
export const ideasAddComment = {
    methods: {
        addComment(data) {
            IdeasHelper.postComment({
                uuid: this.uuid(data.uuid),
                parent: data.parent ? data.parent : 0,
                comment: data.comment,
                attachment: data.attachment,
            }).then(resp => {
                if (data.commentObject) {
                    data.commentObject.children.push(resp.data)
                } else {
                    this.$refs.refReviews.comments.push(resp.data)
                }
                this.idea.comments_count++
            })
        },
        likeComment(data) {
            IdeasHelper.postCommentLike(data.id).then(resp => {
                if (resp.data.id) {
                    data.commentObject.i_like = true
                    data.commentObject.likes_count++
                } else {
                    data.commentObject.i_like = false
                    data.commentObject.likes_count--
                }
            })
        },
    },
}
