<template>
    <div class="">
        <base-followers
            v-if="ideators.length"
            :followers="ideators"
            :height-condition="true"
        ></base-followers>
    </div>
</template>

<script>
import _ from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

export default {
    components: {},
    data: function() {
        return {
            ideators: [],
        }
    },
    computed: {
        ...mapGetters(['ideaObject']),
    },
    created() {
        if (this.ideaObject && this.ideaObject.members) this.setIdeators()
    },
    methods: {
        setIdeators() {
            let user = {
                ...this.ideaObject,
                ...{ user_detail: this.ideaObject.owner_details },
            }
            let allMembers = this.ideaObject.owner_details
                ? _.concat([user], this.ideaObject.members)
                : [...this.ideaObject.members]
            this.ideators = cloneDeep(allMembers)
        },
    },
    watch: {
        ideaObject() {
            this.setIdeators()
        },
    },
}
</script>
